<template>
  <ValidationObserver ref="observer" v-slot="{ invalid, validated }">
    <form @submit.prevent="resetPassword">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="font-weight-bold text-center">
            {{ $t("auth.forgot_password_header") }}
          </p>
        </v-col>
        <v-col cols="12">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('auth.username')"
            vid="login"
            rules="required"
          >
            <v-text-field
              v-model="login"
              :error-messages="errors"
              :label="$t('auth.username')"
              class="rounded-md"
              background-color="lightAccent"
              flat
              solo
            >
              <template v-slot:prepend-inner>
                <v-icon size="20" v-text="'$vuetify.icons.profile'" />
              </template>
            </v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12">
          <v-btn
            type="submit"
            color="primary"
            class="font-weight-black pa-6 rounded-md"
            block
            depressed
            :disabled="invalid && validated"
          >
            {{ $t("auth.reset_password") }}
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { "Content-type": "application/json" }
});

export default {
  name: "ForgotPasswordForm",

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    login: null
  }),

  methods: {
    async resetPassword() {
      if (await this.$refs.observer.validate()) {
        this.$store.commit("app/fetching", true);

        api
          .post("password/reset", {
            login: this.login
          })
          .then(({ data }) => {
            if ("error" in data) {
              switch (data.error) {
                case "client_params_require":
                  this.$refs.observer.setErrors({
                    login: ["The login field is required"]
                  });
                  break;
                case "client_not_found":
                  this.$refs.observer.setErrors({
                    login: [
                      this.$t("auth.user_not_found", { login: this.login })
                    ]
                  });
                  break;
                default:
                  this.$refs.observer.setErrors({
                    login: [this.$t("auth.invalid_data")]
                  });
              }
            }

            const message = this.$t("auth.password_email_sent");

            if ("success" in data) this.$emit("success", message);
          })
          .finally(() => this.$store.commit("app/fetching", false));
      }
    }
  }
};
</script>
