<template>
  <ValidationObserver ref="observer" v-slot="{ invalid, validated }">
    <form @submit.prevent="updatePassword">
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('auth.password')"
        vid="new_password"
        rules="required|min:5"
      >
        <v-text-field
          v-model="newPassword"
          :error-messages="errors"
          :label="$t('auth.new_password')"
          type="password"
          class="rounded-md"
          background-color="lightAccent"
          flat
          solo
        >
          <template v-slot:prepend-inner>
            <v-icon size="20" v-text="'$vuetify.icons.lock'" />
          </template>
        </v-text-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('auth.password')"
        vid="confirmed_password"
        rules="required|confirmed:new_password"
      >
        <v-text-field
          v-model="confirmedPassword"
          :error-messages="errors"
          :label="$t('auth.confirm_password')"
          type="password"
          class="rounded-md"
          background-color="lightAccent"
          flat
          solo
        >
          <template v-slot:prepend-inner>
            <v-icon size="20" v-text="'$vuetify.icons.lock'" />
          </template>
        </v-text-field>
      </ValidationProvider>

      <v-btn
        type="submit"
        color="primary"
        class="font-weight-black pa-6 rounded-md"
        block
        depressed
        :disabled="invalid && validated"
      >
        {{ $t("auth.reset_password") }}
      </v-btn>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { "Content-type": "application/json" }
});

export default {
  name: "ChangePasswordForm",

  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    newPassword: null,
    confirmedPassword: null
  }),

  methods: {
    async updatePassword() {
      if (await this.$refs.observer.validate()) {
        this.$store.commit("app/fetching", true);

        const body = {
          code: String(this.$route.query.code),
          new_password: this.newPassword
        };

        api
          .post("password/reset/confirm", body)
          .then(({ data }) => {
            if ("error" in data) {
              switch (data.error) {
                case "client_password_same":
                  this.$refs.observer.setErrors({
                    confirmed_password: [
                      this.$t("auth.password_matches_current")
                    ]
                  });

                  break;
                default:
                  this.$refs.observer.setErrors({
                    confirmed_password: [this.$t("auth.invalid_data")]
                  });
              }
            }

            const message = this.$t("auth.password_changed");

            if ("success" in data) this.$emit("success", message);
          })
          .finally(() => this.$store.commit("app/fetching", false));
      }
    }
  }
};
</script>
