var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword($event)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('auth.password'),"vid":"new_password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-md",attrs:{"error-messages":errors,"label":_vm.$t('auth.new_password'),"type":"password","background-color":"lightAccent","flat":"","solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"20"},domProps:{"textContent":_vm._s('$vuetify.icons.lock')}})]},proxy:true}],null,true),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('auth.password'),"vid":"confirmed_password","rules":"required|confirmed:new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-md",attrs:{"error-messages":errors,"label":_vm.$t('auth.confirm_password'),"type":"password","background-color":"lightAccent","flat":"","solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"20"},domProps:{"textContent":_vm._s('$vuetify.icons.lock')}})]},proxy:true}],null,true),model:{value:(_vm.confirmedPassword),callback:function ($$v) {_vm.confirmedPassword=$$v},expression:"confirmedPassword"}})]}}],null,true)}),_c('v-btn',{staticClass:"font-weight-black pa-6 rounded-md",attrs:{"type":"submit","color":"primary","block":"","depressed":"","disabled":invalid && validated}},[_vm._v(" "+_vm._s(_vm.$t("auth.reset_password"))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }